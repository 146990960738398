import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import Lightbox from 'react-image-lightbox';
import 'swiper/swiper.min.css';
import 'react-image-lightbox/style.css';
import './ImageSlider.css';
SwiperCore.use([Navigation]);

const ImageSlider = ({ images, isLoading }) => {
    const [photoIndex, setPhotoIndex] = useState(Math.floor(images.length / 2));
    const [isOpen, setIsOpen] = useState(false);
    const [loadedImages, setLoadedImages] = useState([]);
    const [showSlider, setShowSlider] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showLayout, setShowLayout] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const loadImages = async () => {
            const promises = images.map((image) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = `${image.Url}?width=1000&height=600&scale=both&mode=crop`;
                    img.alt = `${image.Alt}`;
                    img.onload = () => resolve(image.Url);
                    img.onerror = reject;
                });
            });

            try {
                const loaded = await Promise.all(promises);
                setLoadedImages(loaded);
            } catch (error) {
                console.error('One or more images failed to load', error);
            }
        };

        loadImages();
    }, [images]);

    useEffect(() => {
        setShowSlider(true);

        setTimeout(() => {
            setShowLayout(false);
        }, 1500);
    }, [isLoading]);


    const handleSlideClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const handleViewAllClick = () => {
        setPhotoIndex(0);
        setIsOpen(true);
    };

    // Function to determine height based on window width
    const determineHeight = () => {
        if (windowWidth > 1200) { // For large screens
            return '600px';
        } else if (windowWidth > 768) { // For medium screens
            return '400px';
        } else { // For small screens
            return '220px';
        }
    };


 
    return (
        <div className={`tw-relative  tw-bg-slate-200`} style={{ height: determineHeight(), }}>
            <div className={`tw-absolute tw-z-10 tw-inset-0  tw-bg-slate-200 ${!showLayout ? 'tw-hidden' : 'tw-block'}`} style={{ height: determineHeight(), }}>

            </div>
            

            <div className={` ${!showLayout ? 'opacity-100' : 'opacity-0'}`}>

                {(images.length) && (
                    <Swiper

                        key={loadedImages.join('_')}
                        navigation
                        spaceBetween={15}
                        centeredSlides={true}
                        loop={true}
                        //initialSlide={photoIndex}
                        //onSlideChange={({ activeIndex }) => setPhotoIndex(activeIndex)}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 1.5,
                            },
                            // when window width is <= 639px
                            0: {
                                slidesPerView: 1,
                            }
                        }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={image.Id} style={{ padding: '0 2px' }}>
                                <div
                                    style={{
                                        backgroundImage: `url(https://www.ourmadeira.com/${image.Url})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: determineHeight(),
                                        width: '100%',
                                        borderRadius: '1px' // optional, for styling
                                    }}
                                    onClick={() => handleSlideClick(index)}
                                >
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}

                <button onClick={handleViewAllClick} className="view-all-photos">View all photos ({images.length})</button>

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex % images.length].Url}
                        nextSrc={images[(photoIndex + 1) % images.length].Url}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].Url}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ImageSlider;