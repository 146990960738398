/** @type {import('tailwindcss').Config} */
module.exports = {
  purge: {
    content: [
      "./src/**/*.{js,jsx,ts,tsx}",
    ],
    options: {
      safelist: [
        'tw-row-span-1',
        'tw-row-span-2',
        'tw-col-span-1',
        'tw-col-span-2',
        'tw-col-span-3',
        'tw-col-span-4',
        'tw-col-span-5',
        'md:tw-row-span-1',
        'md:tw-row-span-2',
        'md:tw-col-span-1',
        'md:tw-col-span-2',
        'md:tw-col-span-3',
        'md:tw-col-span-4',
        'md:tw-col-span-5',
        'lg:tw-row-span-1',
        'lg:tw-row-span-2',
        'lg:tw-col-span-1',
        'lg:tw-col-span-2',
        'lg:tw-col-span-3',
        'lg:tw-col-span-4',
        'lg:tw-col-span-5',
        'tw-gap-4',
        'tw-gap-y-6',
        'tw-grid-cols-1',
        'tw-grid-cols-2',
        'tw-grid-cols-3',
        'tw-grid-cols-4',
        'tw-grid-cols-5',
        'tw-grid-cols-6',
        'sm:tw-grid-cols-1',
        'md:tw-grid-cols-1',
        'lg:tw-grid-cols-1',
        'md:tw-grid-cols-2',
        'lg:tw-grid-cols-2',
        'md:tw-grid-cols-3',
        'lg:tw-grid-cols-3',
        'md:tw-grid-cols-4',
        'lg:tw-grid-cols-4',
        'md:tw-grid-cols-5',
        'lg:tw-grid-cols-5',
        'md:tw-grid-cols-6',
        'lg:tw-grid-cols-6',
      ]
    }
  },
  prefix: 'tw-',
  theme: {
    extend: {
      colors: {
        primary: '#03aeef',
        primaryHover: '#0590c5',
        primary_dark: '#272727',
        secondary: '#03aeef',
      },
    },
  },
  plugins: [],
}