import React, { useEffect, useState, useContext } from 'react';
import { Link, Element, Events } from 'react-scroll';
import { CurrencyContext } from '../../contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import "tailwindcss/tailwind.css";
import CalendarDataContext from '../../contexts/CalendarDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faCalendar, faLocationCrosshairs,  faDollarSign } from '@fortawesome/pro-light-svg-icons';
import { faStar} from '@fortawesome/pro-solid-svg-icons';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './property.css';
 
import ImageSlider from '../../components/PropertyPage/ImageSlider/ImageSlider';
import BookingForm from '../../components/PropertyPage/BookingForm/BookingForm';
import BookingFormMobile from '../../components/PropertyPage/BookingFormMobile/BookingFormMobile';

import Description from '../../components/PropertyPage/Description/Description';
import Amenities from '../../components/PropertyPage/Amenities/Amenities';
import Rates from '../../components/PropertyPage/Rates/Rates';
import AvailabilityCalendar from '../../components/PropertyPage/AvailabilityCalendar/AvailabilityCalendar';
import BreadCrumbs from '../../components/PropertyPage/BreadCrumbs/BreadCrumbs';
 
import Map from '../../components/PropertyPage/Map/Map';
import Reviews from '../../components/PropertyPage/Reviews/Reviews';
import MainAttrs from '../../components/PropertyPage/MainAttrs/MainAttrs';
import EnquiryForm from '../../components/PropertyPage/EnquiryForm/EnquiryForm';
import Modal from 'react-bootstrap/Modal';
 

const icons = {
    Description: faHome,
    Amenities: faList,
    Rates: faDollarSign,
    Availability: faCalendar,
    Map: faLocationCrosshairs,
    Reviews: faStar,
};

const MenuItem = ({ name, to }) => {
    return (
        <Link
            activeclassName="tw-border-b-2  tw-border-primary"
            to={to}
            spy={true}
            smooth={true}
            duration={300}
            className="tw-cursor-pointer tw-px-4 tw-font-bold tw-no-underline tw-py-2 tw-flex tw-items-center"
        >
            <FontAwesomeIcon icon={icons[name]} className="tw-mr-2 " />
            <span className="lg:tw-inline tw-hidden">{name}</span>
        </Link>
    );
};

const Property = ({ pageId, keyID, GoogleMapAPIKey }) => {
    const [isLoading, setIsLoading] = useState(true);
    const windowSize = useWindowSize();
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [calendarData, setCalendarData] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [propertyData, setPropertyData] = useState({});
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [images, setImages] = useState([]);
    const [reviews, setReviews] = useState([]);

    const [pageH1Title, setPageH1Title] = useState('');
    const [intro, setIntro] = useState('');
    const [description, setDescription] = useState('');
    const [propertyRates, setPropertyRates] = useState([]);
    const [geoLocation, setGeoLocation] = useState([]);
    const [distances, setDistances] = useState([]);
    const [locationDescription, setLocationDescription] = useState('');
    const [propertyAttributes, setPropertyAttributes] = useState({});
    const [floorPlan, setFloorPlan] = useState('');
 
    const [cons, setCons] = useState('');
    const [pros, setPros] = useState('');

    const [maxOccupancy, setMaxOccupancy] = useState(1);
    const [maxPets, setMaxPets] = useState(1);
    const [currencySymbol, setCurrencySymbol] = useState("£");
    const [hideCalendar, setHideCalendar] = useState(false);
    const [disableAvailability, setDisableAvailability] = useState(false);
    const [hideRates, setHideRates] = useState(false);
    const [plotSize, setPlotSize] = useState(null);
    const [propertyLicence, setPropertyLicence] = useState('');
    const [propertyReference, setPropertyReference] = useState('');
    const [regionDescription, setRegionDescription] = useState('');
    const [propertyRentalNotes, setPropertyRentalNotes] = useState('');
    const [virtualTour, setVirtualTour] = useState('');
    const [virtualTourTitle, setVirtualTourTitle] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [propertyMinRate, setPropertyMinRate] = useState(null);
    const [propertyMaxRate, setPropertyMaxRate] = useState(null);
    const [hideOnlineBooking, setHideOnlineBooking] = useState(null);

    const [showEnquire, setShowEnquire] = useState(false);
    const handleCloseEnquire = () => setShowEnquire(false);
    const handleShowEnquire = () => setShowEnquire(true);
    

    useEffect(() => {
        if (windowSize.width > 800) {
            setIsMobile(false);
        }

        if (windowSize.width < 800) {
            setIsMobile(true);
        }
    }, [windowSize.width]);

 
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}apis/propertypage?pageid=${pageId}&Currency=${currency}`) //${currency}`)
            .then(response => response.json())
            .then(data => {
                setPropertyData(data.Property.Details)
                setIntro(data.Property.Details.Intro);
                setCons(data.Property.Details.Cons);
                setPros(data.Property.Details.Pros);
                setPageH1Title(data.Property.Details.PageH1Title);
                setReviews(data.Property.Details.Reviews);
                setDescription(data.Property.Details.MainDescription);
                setImages(data.Property.Details.SliderImages);
                setCalendarData(data.Property.Details.Calendar);
                setFloorPlan(data.Property.Details.FloorPlan);

                

                setPropertyRates(data.Property.Details.PropertyRates);
                setPropertyAttributes(data.Property.Details.PropertyAttributes);
                setGeoLocation(data.Property.Details.GeoLocation);
                setDistances(data.Property.Details.Distances);
                setLocationDescription(data.Property.Details.LocationDescription);
                 
                setIsLoading(false);  // Data has been fetched successfully

                setMaxOccupancy(data.Property.Details.MaxOccupancy);
                setMaxPets(data.Property.Details.MaxPets);
                setCurrencySymbol(data.Property.Details.CurrencySymbol);
                setHideCalendar(data.Property.Details.HideCalendar);
                setDisableAvailability(data.Property.Details.DisableAvailability);
                setHideRates(data.Property.Details.HideRates);
                setPlotSize(data.Property.Details.PlotSize);
                setPropertyLicence(data.Property.Details.PropertyLicence);
                setPropertyReference(data.Property.Details.PropertyReference);
                setRegionDescription(data.Property.Details.RegionDescription);
                setPropertyRentalNotes(data.Property.Details.PropertyRentalNotes);
                setVirtualTour(data.Property.Details.VirtualTour);
                setVirtualTourTitle(data.Property.Details.VirtualTourTitle);
                setBreadcrumbs(data.Settings.BreadCrumbs);
                setPropertyMinRate(data.Property.Details.PropertyMinRate);
                setPropertyMaxRate(data.Property.Details.PropertyMaxRate);
                setHideOnlineBooking(data.Property.Details.HideOnlineBooking);
            })
            .catch(error => {
                console.error('Error fetching blocked dates:', error);
                setIsLoading(false);  // Data has been fetched successfully
            });
    }, [currency]);


 

    useEffect(() => {
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    const handleDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    return (
        <>
 
            <div className="tw-w-full tw-overflow-hidden">
                {/* Image slider */}
                <ImageSlider images={images} isLoading={isLoading} />
            </div>


            <Modal show={showEnquire} onHide={handleCloseEnquire}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EnquiryForm
                        pageId={pageId}
                        currencySymbol={currency}
                    />
                </Modal.Body>
            </Modal>

            <div className="tw-max-w-[1280px] tw-mx-auto tw-py-2">

                <div className={`tw-w-full tw-grid tw-grid-cols-12 tw-gap-4 tw-mt-2 ${windowSize.width < 1024 ? 'tw-grid-flow-row-dense' : ''}`}>
                    {/* Sidebar */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-4 tw-order-last' : 'tw-order-first'}`}>
                        <div className="tw-sticky tw-top-0 tw-z-50 lg:tw-h-[120vh] tw-pb-5">

                           {!isMobile && 
                                <>
                                  <div className="tw-white tw-shadow-xl tw-mt-3 tw-pb-3">
                                        <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                            {<BookingForm
                                                selectedDates={selectedDates}
                                                onDateChange={handleDateChange}
                                                maxOccupancy={maxOccupancy}
                                                maxPets={maxPets}
                                                currencySymbol={currency}
                                                pageId={pageId}
                                                disableAvailability={disableAvailability}
                                                hideOnlineBooking = {hideOnlineBooking}
                                            />}
                                        </CalendarDataContext.Provider>
                                        <span
                                            onClick={handleShowEnquire}
                                            className="hover:tw-text-[#272727] tw-cursor-pointer tw-m-5 tw-border tw-border-black tw-text-black tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200"
                                        >
                                            Make An Enquiry
                                        </span>
                                    </div>

                                </>
                            } 


                            
                           {isMobile && 
                                <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                    {<BookingFormMobile
                                        selectedDates={selectedDates}
                                        onDateChange={handleDateChange}
                                        maxOccupancy={maxOccupancy}
                                        maxPets={maxPets}
                                        currencySymbol={currencySymbol}
                                        pageId={pageId}
                                        disableAvailability={disableAvailability}
                                        propertyMaxRate={propertyMaxRate}
                                        propertyMinRate={propertyMinRate}
                                        setShowEnquire={setShowEnquire}
                                        hideOnlineBooking = {hideOnlineBooking}
                                    />}
                                </CalendarDataContext.Provider>
                            }  
 
                        </div>
                    </div>
                    
                    {/* Main content */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-8 tw-order-first' : 'tw-order-last'}`}>

                       
                        

                        <div className="tw-sticky tw-top-0  tw-bg-white tw-w-full tw-flex tw-justify-between">
                            <MenuItem name="Description" to="description" />
                            <MenuItem name="Amenities" to="amenities" />
                            {!hideRates && <MenuItem name="Rates" to="rates" />}
                            {!hideCalendar && <MenuItem name="Availability" to="availability" />}
                            <MenuItem name="Map" to="map" />
                            <MenuItem name="Reviews" to="reviews" />
                        </div>

                        

                        <div className="  ">
                            {(breadcrumbs && breadcrumbs.length > 0) && <BreadCrumbs data={breadcrumbs} />}
                           
                            {/* Sections */}
                           
                            <Element name="description" className="property-section" >
                                
                                <h1 className="tw-text-3xl tw-mb-0">{pageH1Title}</h1>  
                                
                                {(propertyData.PropertyCountry && propertyData.PropertyCity) &&
                                    <div className=" tw-flex tw-items-center tw-py-1">
                                        <FontAwesomeIcon icon={faLocationCrosshairs} className="tw-mr-2" />
                                        <span className="tw-mr-2">{propertyData.PropertyCountry}</span>
                                        <span className="tw-mr-2">|</span>
                                        <span className="tw-mr-2">{propertyData.PropertyCity}</span>
                                    </div>
                                }  

                                <div>
                                    {propertyData.Reviews && propertyData.Reviews.length > 0 && (
                                        <div>Our Rating: <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} className="tw-mr-1" />
                                            {(
                                                propertyData.Reviews.reduce((acc, review) => acc + review.Rating, 0) / propertyData.Reviews.length
                                            ).toFixed(1)}</div>
                                    )}
                                </div> 

                                <MainAttrs propertyAttributes={propertyAttributes} />  
                                
                                <Description intro={intro} description={description} cons={cons} pros={pros} isLoading={isLoading} />
                            
                                {virtualTour && 
                                   <>
                                        { virtualTourTitle }
                                        <div className="virtualTour" dangerouslySetInnerHTML={{ __html: virtualTour }} />
                                   </>
                                }
                                {floorPlan && <div className="floorPlan" dangerouslySetInnerHTML={{ __html: floorPlan }} />}
                                {plotSize && <div className="plotSize" dangerouslySetInnerHTML={{ __html: plotSize }} />} 
                                {propertyLicence && 
                                <>
                                    <b>Property Licence: </b>
                                    <span className="propertyLicence tw-ml-2" dangerouslySetInnerHTML={{ __html: propertyLicence }} />
                                </>} 
                                {propertyReference && <div className="propertyReference "><b>Property Reference:</b> {propertyReference}</div>}
                            </Element>

                            <Element name="amenities" className="property-section">
                                <Amenities propertyAttributes={propertyAttributes} isLoading={isLoading} />
                            </Element>

                            {!hideRates &&
                                <Element name="rates" className="property-section">
                                    
                                    <Rates rates={propertyRates} isLoading={isLoading} />
                                    <div className="table-responsive">
                                        {propertyRentalNotes && <div className="propertyRentalNotes" dangerouslySetInnerHTML={{ __html: propertyRentalNotes }} />}
                                    </div>
                                </Element>
                            }

                            {!hideCalendar && 
                                <Element name="availability" className="property-section AvailabilityCalendar">
                                    <CalendarDataContext.Provider value={calendarData}>
                                        <AvailabilityCalendar selectedDates={selectedDates} onDateChange={handleDateChange} isLoading={isLoading} disableAvailability={disableAvailability} />
                                    </CalendarDataContext.Provider>

                                </Element>
                           }
                           
                            <Element name="map" className="property-section">
                                <Map geoLocation={geoLocation} GoogleMapAPIKey={GoogleMapAPIKey} isLoading={isLoading} distances={distances} locationDescription={regionDescription} />
                            </Element>
                            <Element name="reviews" className="property-section">
                                <Reviews reviews={reviews} isLoading={isLoading} />
                            </Element>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Property;